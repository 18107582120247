// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-googleanalytics-js": () => import("/opt/build/repo/src/pages/googleanalytics.js" /* webpackChunkName: "component---src-pages-googleanalytics-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("/opt/build/repo/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-preview-js": () => import("/opt/build/repo/src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-quarter-js": () => import("/opt/build/repo/src/pages/Quarter.js" /* webpackChunkName: "component---src-pages-quarter-js" */)
}

